import '#js/components/percentageInput'
import { LitElement, css, html } from 'lit'
import { bindValue, debounce, localize } from '#js/components/utils'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import { setLocale } from '#js/components/lit-i18n'

export class SignUpRate extends LitElement {
  static get properties () {
    return {
      totalEmployeeCount: { type: Number },
      memberCount: { type: Number },
      monthly_employee_churn_rate: { type: Number, reflect: true },
      modules_coverage: { type: Number, reflect: true },
      contractRuntime: { type: Number },
      csrfToken: { type: String },
      apiUrl: { type: String }
    }
  }

  constructor () {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)

    this.saveState = debounce(async function (newState) {
      await fetch(this.apiUrl, {
        method: 'put',
        body: JSON.stringify(newState),
        headers: {
          'Content-Type': 'application/json', 'X-CSRFToken': this.csrfToken
        },
        credentials: 'same-origin'
      })
    }, 1000)
  }

  attributeChangedCallback (name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval)

    if (oldval !== null) {
      this.saveState({ [name]: parseFloat(newval) })
    }
  }

  addressableEmployees () {
    return Math.round(this.totalEmployeeCount * this.modules_coverage)
  }

  totalEmployeeChurnRate () {
    return Math.pow((1 - this.monthly_employee_churn_rate), this.contractRuntime)
  }

  getAnnualEmployeeChurnRate () {
    return 1 - Math.pow(1 - this.monthly_employee_churn_rate, 12)
  }

  setAnnualEmployeeChurnRate (event) {
    this.monthly_employee_churn_rate = (1 - Math.pow(1 - event.target.value, 1 / 12)).toFixed(3)
  }

  reachableMembers () {
    return Math.round(this.memberCount * this.totalEmployeeChurnRate())
  }

  /**
   * Return the percentage of the addressable employee base that is currently reached.
   * @returns {number} - A number between 0 and 1.
   */
  result () {
    return Math.min(1, this.reachableMembers() / this.addressableEmployees())
  }

  resultDisplay () {
    return this.result().toLocaleString(globalThis.language, {
      style: 'percent', maximumFractionDigits: 1
    })
  }

  monthlyHires () {
    return Math.round(this.totalEmployeeCount * this.monthly_employee_churn_rate)
  }

  annualHires () {
    return Math.round(this.totalEmployeeCount * this.getAnnualEmployeeChurnRate())
  }

  static styles = css`
    progress {
      display: block;
      appearance: none;
      height: 1em;
      margin: 0 auto;
      width: 66%;
      align-self: center;
    }

    progress::-webkit-progress-bar {
      background-color: var(--darker-gray);
      border-radius: 0.5em;
    }

    progress::-webkit-progress-value {
      background-color: var(--brand-color);
      border-radius: 0.5em;
    }

    progress::-moz-progress-bar {
      border-radius: 0.5em;
      color: var(--brand-color);
    }

    aside {
      font-size: 80%;
    }

    em {
      font-style: normal;
      text-decoration: underline;
      text-decoration-color: var(--brand-color);
      text-decoration-thickness: 0.1em;
      text-underline-offset: 0.2em;
      text-decoration-style: dotted;
    }
  `

  render () {
    const monthlyEmployeeChurnRateInput = html`
        <percentage-input
                id="monthly-employee-churn-rate"
                value=${this.monthly_employee_churn_rate}
                @change="${bindValue(this, 'monthly_employee_churn_rate')}"
        ></percentage-input>
    `
    const annualEmployeeChurnRateInput = html`
        <percentage-input
                id="annual-employee-churn-rate"
                value=${this.getAnnualEmployeeChurnRate()}
                @change="${this.setAnnualEmployeeChurnRate}"
        ></percentage-input>`

    const moduleCoverageInput = html`
        <percentage-input
                id="voiio-modules-coverage"
                value=${this.modules_coverage}
                @change="${bindValue(this, 'modules_coverage')}"
        ></percentage-input>`
    const reachableMembers = html`<strong>${localize(this.reachableMembers())}</strong>`
    const result = html`<strong>${localize(this.resultDisplay())}</strong>`
    return html`
        <div style="text-align: center">
            <h3>
              ${this.resultDisplay()} ${msg('sign-up quota')}
              (${reachableMembers} ${msg(html`members`)})
            </h3>
            <progress value="${this.result()}" max="1">
                ${this.resultDisplay()}
            </progress>
        </div>
        <p>
            ${msg(html`With a sign-up quota of ${result} you are currently reaching about ${reachableMembers} employees.`)}
            ${msg('Those numbers are calculated based on the following factors and benchmarks:')}
        </p>
        <dl>
            <dt>${msg(html`${moduleCoverageInput} module coverage:`)}**</dt>
            <dd>
                ${msg(str`Different voiio modules address different phases in life and therefore different employee groups.
                Based on our benchmarks, your current module selection addresses about ${localize(this.addressableEmployees())}
                of your ${localize(this.totalEmployeeCount)} total employees.
                For example, if you only have child care voiio module, the ${localize(this.addressableEmployees())} addressable employees
                would be parents of children that are currently in right age group.`)}
            </dd>
            <dt style="display: none">${msg(html`${monthlyEmployeeChurnRateInput} monthly employee churn rate:`)}</dt>
            <dd style="display: none">
                ${msg(html`You probably have of new faces joining your company every month.
                A monthly churn rate of ${monthlyEmployeeChurnRateInput} means that you have around ${localize(this.monthlyHires())} new hires every month.
                Or, translated to a yearly churn rate of ${annualEmployeeChurnRateInput}
                this means of your ${localize(this.totalEmployeeCount)} employees ${localize(this.annualHires())} have been working at your company for less than a year.`)}
            </dd>
        </dl>
        <aside>
            ${msg(html`*<em>underlined</em> values can be edited`)}
        </aside>
    `
  }
}

window.customElements.define('sign-up-rate', SignUpRate)
